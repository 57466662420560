import React from "react";
import ppaLogo from "../../img/ppa-footer-logo.svg";
import "./styles/Footer.css";
import facebookIcon from "../../img/footer-fb.png";
import youtubeIcon from "../../img/footer-yt.png";
import linkedInIcon from "../../img/footer-ln.png";
import telegramIcon from "../../img/footer-tg.png";
import instagramIcon from "../../img/footer-ig.png";
import { Link } from "react-router-dom";
import TalkToUsButton from "../Common/TalkToUsButton";
import { sendEventToGTM } from "../../GoogleAnaytics/gtmManager";
import { getCurrentCourse } from "../../courses/CourseUtility";
import { GTM_TRACKER } from "../../GoogleAnaytics/gtmConstants";

const Footer = () => {
    return (
        <div className="footer-container">
            <div className="footer-header">
                <img src={ppaLogo} alt="" />
                <p>Programming Pathshala</p>
            </div>
            <div className="footer-contents">
                <div className="footer-section-company-courses">
                    <div className="footer-section-courses">
                        <p className="customCss">COURSES</p>
                        <a
                            onClick={() => {
                                sendEventToGTM({
                                    event: "course-page-click",
                                    course: "Renaissance",
                                });
                            }}
                            href="/crack-coding-interviews#"
                        >
                            <p>Renaissance for Top Tech Companies</p>
                        </a>
                        <a
                            onClick={() => {
                                sendEventToGTM({
                                    event: "course-page-click",
                                    course: "Low-Level-Design",
                                });
                            }}
                            href="/low-level-design#"
                        >
                            <p>Low Level Design and Concurrency</p>
                        </a>

                        <a
                            onClick={() => {
                                sendEventToGTM({
                                    event: "course-page-click",
                                    course: "Java-springboot",
                                });
                            }}
                            href="/java-springboot"
                        >
                            <p>Backend Development with Java Spring Boot</p>
                        </a>

                        <a
                            onClick={() => {
                                sendEventToGTM({
                                    event: "course-page-click",
                                    course: "Dsa-Crash-Course",
                                });
                            }}
                            href="/dsa-and-algorithms-essentials#"
                        >
                            <p>Crash Course in Data Structures & Algorithms</p>
                        </a>

                        <a
                            onClick={() => {
                                sendEventToGTM({
                                    event: "course-page-click",
                                    course: "Cpp",
                                });
                            }}
                            href="/begin-with-c++"
                        >
                            <p>Begin with Programming in C++</p>
                        </a>
                    </div>
                    <div className="footer-section-courses new">
                        <span className="line-break"></span>
                        <a
                            onClick={() => {
                                sendEventToGTM({
                                    event: "course-page-click",
                                    course: "Python",
                                });
                            }}
                            href="/begin-with-python"
                        >
                            <p>Python Full Course</p>
                        </a>
                        <p>System Design for Professionals</p>
                        <p>Be a Data Engineer</p>
                        <p>Full Stack Development in MERN</p>
                    </div>
                </div>

                <div className="footer-section">
                    <div className="footer-section-company">
                        <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://programmingpathshala.com/"
                            className="links-font-style"
                            to=""
                        >
                            <p>About Us</p>
                        </a>
                        <a
                            className={GTM_TRACKER.SUCCESS_STORIES}
                            href="/crack-coding-interviews/stories"
                        >
                            <p>Success Stories</p>
                        </a>

                        <a
                            className={GTM_TRACKER.BLOGS}
                            target="_blank"
                            rel="noreferrer"
                            href="https://blogs.programmingpathshala.com/"
                        >
                            <p>Blogs</p>
                        </a>
                        <a
                            className={GTM_TRACKER.EVENTS}
                            target="_blank"
                            rel="noreferrer"
                            href="https://events.programmingpathshala.com/"
                        >
                            <p>Events</p>
                        </a>
                    </div>
                    <div className="footer-section-policies">
                        <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://forms.gle/nG8FArKkwNEJucqE7"
                            className="links-font-style"
                            to=""
                        >
                            <p>Get Scholarships</p>
                        </a>

                        <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://forms.gle/jsepbL2id7gpkSLeA"
                            className="links-font-style"
                            to=""
                        >
                            {" "}
                            <p>Teach with Us</p>
                        </a>
                        <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://community.programmingpathshala.com/"
                            className="links-font-style"
                            to=""
                        >
                            {" "}
                            <p>Join our Community</p>
                        </a>
                        <div className="policies">
                            <a
                                target="_blank"
                                rel="noreferrer"
                                href="https://renaissance.programmingpathshala.com/terms-and-conditions"
                                className="links-font-style"
                                to=""
                            >
                                <p>Terms and Conditions</p>
                            </a>
                            <Link to="/refund-policy">
                                <p>Refund Policy</p>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-icons-wrapper">
                <a
                    href="https://www.facebook.com/Programmingpathshala.1"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <img src={facebookIcon} alt="" />
                </a>
                <a
                    href="https://www.youtube.com/channel/UC4uPfwRHPbYlmjNMiKIdWNg"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <img src={youtubeIcon} alt="" />
                </a>
                <a
                    href="https://www.linkedin.com/school/programming-pathshala/mycompany/"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    {" "}
                    <img src={linkedInIcon} alt="" />
                </a>
                <a
                    href="https://t.me/programmingpathshala"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <img src={telegramIcon} alt="" />
                </a>
                <a
                    href="https://www.instagram.com/programmingpathshala/"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <img src={instagramIcon} alt="" />
                </a>
            </div>
            <div className="footer-line-break">
                <p>All rights reserved with @Programming Pathshala</p>
            </div>
        </div>
    );
};

export default Footer;
