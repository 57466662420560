import React, { useState, useEffect } from "react";
import "./Pricing.css";
import swal from "sweetalert";
import scholarshipImg from "./../../../img/cpp-scholarship.png";
import FreeTrailUserSubscriptionPlan from "./FreeTrailUserSubscriptionPlan";
import upgradeGiftBox from "./../../../img/upgradeGiftBox.png";
import {
    getPlans,
    implementInitiatePayments,
    placeOrderAndShowRz,
    updatePlans,
} from "./SubscriptionClient";
import { useSubscriptionContext } from "./SubscriptionContext";
import MovingSharpIcon from "@mui/icons-material/MovingSharp";
import { useGlobalContext } from "../../../Contexts/GlobalContext";
import { APPLY_REFERRAL_OR_DISCOUNT_URL } from "./SubscriptionConstants";
import hoorayImg from "./../../../img/hoorayImg.png";
import Loader from "../../Loader/Loader";
import ActionButton from "../../Common/ActionButton";
import { style } from "../../Common/Theme";
import { useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import { sendEventToGTM } from "../../../GoogleAnaytics/gtmManager";
import { gaCourseNameMap } from "../../../courses/CourseConstants";
import { getCurrentCourse } from "../../../courses/CourseUtility";
import { GTM_TRACKER } from "../../../GoogleAnaytics/gtmConstants";

const Pricing = ({ couponCode, setCouponCode }) => {
    const navigate = useNavigate();
    const {
        setUpgradeCouponCode,
        setReferralOrDiscountCouponCode,
        referralOrDiscountCouponCode,
        setLoaded,
        loaded,
        errorMessage,
        setErrorMessage,
        upgradeCouponCode,
    } = useSubscriptionContext();

    const {
        loggedIn,
        onFreeTrial,
        subscribed,
        currentLoggedInUserEmail,
        couponDiscountPrice,
        everSubscribed,
    } = useGlobalContext();

    const [plans, setPlans] = useState([]);
    const [isCouponValid, setIsCouponValid] = useState(null);
    const [disountPrice, setDiscountPrice] = useState("");
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [modalAdditionalDiscountInRupees, setModalAdditionalDiscountInRupees] = useState(0);
    const [orderId, setOrderId] = useState("");
    const [key, setKey] = useState("");
    const [amount, setAmount] = useState(0);
    const [currency, setCurrency] = useState(0);
    const [razorpay_payment_id, setRazorpayPaymentsId] = useState("");
    const [showSavedMoney, setShowSavedMoney] = useState(false);
    const [couponPlans, setCouponPlans] = useState([]);
    const [errorSnackbar, setErrorSnackBar] = useState(false);
    const [paymentErrorModal, setPaymentErrorModal] = useState(false);
    let yearlySubscriptionOrHalfYearlyUpgradationDiscount = 0;
    let monthlyDiscount = 0;
    let halfYearlySubscriptionOrQuaterlyUpgradationDiscount = 0;

    useEffect(() => {
        getPlans(setPlans, setErrorSnackBar, getErrorDisplayModal, setLoaded);
    }, []);

    const handleUserInput = (event) => {
        console.log(event.target.value);
        setCouponCode(event.target.value);
    };

    const handleRemove = () => {
        setIsCouponValid(null);
        setUpgradeCouponCode("");
        setReferralOrDiscountCouponCode("");
        setCouponCode("");
        setErrorSnackBar(false);
    };

    const handleCouponSubmit = (event) => {
        event.preventDefault();
        let setDiscountCouponCode;
        let url;
        if (couponCode) {
            setReferralOrDiscountCouponCode(couponCode);
            setDiscountCouponCode = setReferralOrDiscountCouponCode;
            url = APPLY_REFERRAL_OR_DISCOUNT_URL;
            setErrorSnackBar(false);

            updatePlans(
                updateCouponPlans,
                setDiscountCouponCode,
                couponCode,
                url,
                yearlySubscriptionOrHalfYearlyUpgradationDiscount,
                setErrorSnackBar,
                setDiscountPrice,
                setLoaded,
                setIsModalVisible,
                setShowSavedMoney,
            );
        }
    };

    const setPaymentDetails = (orderId, key, amount, currency, razorpay_payment_id) => {
        setOrderId(orderId);
        setKey(key);
        setAmount(amount);
        setCurrency(currency);
        setRazorpayPaymentsId(razorpay_payment_id);
    };

    const setPaymentsId = async (razorpay_payment_id, metadata) => {
        sendEventToGTM({
            event: "successful-payment",
            course: `${gaCourseNameMap[getCurrentCourse()]}`,
            amount: metadata?.amount,
            currency: metadata?.currency,
        });
        await swal("Done", "Payment Successful.", "success");
        setRazorpayPaymentsId(razorpay_payment_id);
    };

    const initiatePayment = (subscriptionDetails) => {
        try {
            let data;
            let paymentURL;
            let { subscription_id } = subscriptionDetails;
            data = {
                subscriptionID: subscription_id,
                username: currentLoggedInUserEmail,
                referralOrDiscountCode: referralOrDiscountCouponCode,
            };
            implementInitiatePayments(
                data,
                setPaymentDetails,
                placeOrderAndShowRz,
                currentLoggedInUserEmail,
                setPaymentsId,
                paymentURL,
                setLoaded,
                setPaymentErrorModal,
                setErrorMessage,
            );
        } catch (error) {}
    };

    const setCouponApplied = () => {
        setIsCouponValid(true);
    };

    const updateCouponPlans = (plans) => {
        setCouponPlans(plans);
        setCouponApplied();
    };

    const getOnSuccessOfCouponCodeModal = (couponDiscountPrice) => {
        return (
            <section onClick={() => setIsModalVisible(false)} className="modal-wrapper-bg">
                <div className="success-modal-wrapper">
                    <img src={hoorayImg} alt="" />
                    <h3>'{couponCode}' applied </h3>
                    <h4>You have saved upto ₹ {couponDiscountPrice} </h4>
                    <p>with this coupon code</p>
                    <button onClick={() => setIsModalVisible(false)}>Thanks!</button>
                </div>
            </section>
        );
    };

    const getErrorDisplayModal = (errorMessage) => {
        return (
            <section onClick={() => setPaymentErrorModal(false)} className="modal-wrapper-bg">
                <div className="error-message-card-wrapper">
                    <div className="close-mark">x</div>
                    <p>{errorMessage}</p>
                </div>
            </section>
        );
    };

    const handleRemoveCouponCode = () => {
        setTimeout(() => {
            handleRemove();
        }, 1500);
    };

    const geterrorPopUp = () => {
        return (
            <>
                <div className="error-coupon-code-wrapper">
                    <p>This Coupon Code is invalid. Please try again.</p>
                </div>
                {handleRemoveCouponCode()}
            </>
        );
    };

    const applyCoupon = () => {
        return (
            <section className="subscription-coupon-wrapper">
                {isCouponValid && isModalVisible ? (
                    getOnSuccessOfCouponCodeModal(couponDiscountPrice)
                ) : (
                    <></>
                )}
                <div className="subscription-coupon-input-wrapper">
                    <input
                        value={couponCode}
                        type="text"
                        placeholder="Enter Code"
                        onChange={handleUserInput}
                        id="ppa-course-coupon-code-input"
                    />
                    {isCouponValid ? (
                        <button
                            onClick={handleRemove}
                            type="submit"
                            className="subscription-code-submit-button"
                        >
                            <span className="subscription-remove-text">Remove</span>
                        </button>
                    ) : (
                        <button
                            onClick={handleCouponSubmit}
                            type="submit"
                            className="subscription-code-submit-button transition-ease"
                            id="ppa-course-apply-coupon-code-button"
                        >
                            <span>Apply</span>
                        </button>
                    )}
                </div>
                <div className="subscription-coupon-heading-wrapper">
                    <img src={upgradeGiftBox} alt="gift" />
                    <p>I have a Referral Code/Coupon Code!</p>
                </div>

                {errorSnackbar ? geterrorPopUp() : null}
            </section>
        );
    };

    const getSubscriptionPlans = () => {
        let subscriptionPlans = isCouponValid ? couponPlans : plans;

        return (
            <div className="subscription-wrapper">
                {paymentErrorModal && getErrorDisplayModal(errorMessage)}
                {/* {loaded && <Loader />} */}
                {loaded ? (
                    <div className="subscription-loader-wrapper">
                        <CircularProgress />
                    </div>
                ) : (
                    <div className="subscription-contents">
                        <FreeTrailUserSubscriptionPlan
                            subscriptionPlans={subscriptionPlans}
                            isCouponValid={isCouponValid}
                            loggedIn={loggedIn}
                            checkUserSubscribed={subscribed}
                            checkOnFreeTrail={onFreeTrial}
                            initiatePayment={initiatePayment}
                            showSavedMoney={showSavedMoney}
                            everSubscribed={everSubscribed}
                        />
                    </div>
                )}
                {applyCoupon()}
                <div className="EMI-detail">
                    <div className="p-2">
                        <p>
                            To be paid as a one-time payment. Easy EMI options available with HDFC
                            Cards and Zest Money.
                        </p>
                    </div>
                </div>
            </div>
        );
    };

    const getSectionHeadingAndTitle = () => {
        return (
            <div className="pricing-title-and-heading-wrapper">
                <p className="customCssPricing">Pricing</p>
                <p className="customCssPricingPlan">Pricing Plans for You</p>
                {/* <div className="price-increase-wrapper">
          <MovingSharpIcon className="price-increase-icon" />
          <p className="price-increase-text">
            Prices increase from Jan 8, 2024!<br /> Subscribe Now to get the best
            deals
          </p>
        </div> */}
            </div>
        );
    };

    const getScholarshipSection = () => {
        return (
            <div className="ren-scholarship-wrapper">
                <div className="ren-scholarship-about-wrapper">
                    <div className="ren-scholarship-about">
                        <p>
                            Up-to 100% scholarships for students in need. Avail now and achieve your
                            dreams.
                        </p>
                    </div>
                    <div className="ren-scholarship-image-wrapper">
                        <img src={scholarshipImg} alt="" />
                    </div>
                </div>
                <div className="ren-scholarship-content">
                    <p>
                        We vision access to tech education irrespective of your financial situation
                        or social privileges. If you are from underprivileged background or in a
                        circumstance where you will not be able to afford the course, you can apply
                        for our scholarship. We may take up-to 2 weeks to process your scholarship
                        application.
                    </p>
                    <div className="ren-scholarship-button-wrapper">
                        <a
                            className="ren-scholarship-button"
                            href="https://docs.google.com/forms/d/1aob-tQJcuFCtL8H3I8zOtl6zkjVcWB6sfJ3OzuLXcqc/viewform?pli=1&pli=1&edit_requested=true"
                        >
                            Apply for Scholarships
                        </a>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <div className="pricing-wrapper">
            {getSectionHeadingAndTitle()}
            {getSubscriptionPlans()}
            {getScholarshipSection()}
        </div>
    );
};

export default Pricing;
