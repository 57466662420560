import React from "react";
import Slider from "react-slick";
import arrowPrev from "../../img/left-arrow-blue.png";
import arrowNext from "../../img/right-arrow-blue.png";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./styles/SuccessStories.css";
import { RENAISSANCE_SUCCESS_STORIES } from "./Content/RenaissanceSuccessStoriesContent";
import quotesIcon from "../../img/quotes-icon.png";
import linkedin_icon from "../../img/linkedin.png";
import { GTM_TRACKER } from "../../GoogleAnaytics/gtmConstants";

function NextArrow(props) {
  const { className, onClick } = props;
  return (
    <img className={className} onClick={onClick} src={arrowNext} alt="next" />
  );
}

function PrevArrow(props) {
  const { className, onClick } = props;
  return (
    <img className={className} onClick={onClick} src={arrowPrev} alt="prev" />
  );
}

const SuccessStories = () => {
  const sliderSettings = {
    dots: true,
    infinite: true,
    autoplay: false,
    speed: 500,
    slidesToShow: 3,
    arrows: true,
    slidesToScroll: 1,
    customPaging: function (i) {
      return <a />;
    },
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 601,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
    ],
  };
  const getSlidingStories = () => {
    return (
      <Slider {...sliderSettings}>
        {RENAISSANCE_SUCCESS_STORIES &&
          RENAISSANCE_SUCCESS_STORIES.map((success_story, index) => (
            <div className="ren-success-stories-card">
              <div className="ren-success-stories-content">
                <img
                  className="ren-success-stories-card-quotes-img"
                  src={quotesIcon}
                  alt=""
                />
                <div className="ren-success-stories-card-txt">
                  <p>{success_story.text}</p>
                </div>
                <img
                  className="ren-success-stories-card-img"
                  src={success_story.img}
                  alt=""
                />
                <div className="ren-success-stories-name-wrapper">
                  <p className="ren-success-stories-name">
                    {success_story.name}
                  </p>
                  <a href={success_story.link}>
                    <img src={linkedin_icon} alt="" />
                  </a>
                </div>
                <div className="ren-success-stories-company-logo-wrapper">
                  <p className="ren-sde-text">
                    {success_story.job_description}
                  </p>
                  <div className="ren-logo-wrapper">
                    <img src={success_story.company_logo} alt="" />
                  </div>
                </div>
              </div>
            </div>
          ))}
      </Slider>
    );
  };
  return (
    <div className="ren-success-stories-wrapper">
      <h2 className="customCss">Success Stories</h2>
      {getSlidingStories()}
      <div
        className={`${GTM_TRACKER.SUCCESS_STORIES} ren-success-stories-read-more-btn`}
        onClick={() =>
          window.open(
            process.env.REACT_APP_FRONTEND_MAIN_URL +
              "crack-coding-interviews/stories",
            "_self"
          )
        }
      >
        <button>Read More Stories</button>
      </div>
    </div>
  );
};

export default SuccessStories;
