import React, { useEffect } from "react";
import Header from "../Header/Header";
import FreeTrial from "./FreeTrial";
import { setCurrentCourse } from "../../courses/CourseUtility";
import { availableCourses } from "../../courses/CourseConstants";
import { useGlobalContext } from "../../Contexts/GlobalContext";
import Cookies from "universal-cookie";
import { isNULLEMPTYORUNDEFINED } from "../../Utilities/SessionUtility";
import Intro from "./Intro";
import About from "./About";
import Audience from "./Audience";
import Advantages from "./Advantages";
import Curriculum from "./Curriculum";
import Faq from "./Faq";
import ExploreCourses from "../ExploreCourses/ExploreCourses";
import ExploreBlogs from "../ExploreBlogs/ExploreBlogs";
import Prerequisites from "./Prerequisites";
import Mentor from "../Mentor/Mentor";
import Pricing from "./Pricing/Pricing";
import SuccessStories from "./SuccessStories";
import Footer from "../Footer/Footer";
import { setUTMParametersInLocalStorage, useQuery } from "../../Utilities/CommonUtilities";
import { Helmet } from "react-helmet-async";

const LowLevelDesignLandingPage = ({ couponCode, setCouponCode }) => {
    setCurrentCourse(availableCourses.LLD);

    const cookies = new Cookies();
    const { checkSubscribed } = useGlobalContext(); 

    useEffect(() => {
        if (!isNULLEMPTYORUNDEFINED(cookies.get("authorization"))) checkSubscribed();
    }, []);

    const query = useQuery();
    setUTMParametersInLocalStorage(query);

    return (
        <div>
            <Helmet>
                <title>Beginner to Pro: Low-Level Design | Programming Pathshala</title>
                <meta
                    name="description"
                    content="Learn Low-Level Design with no prior experience! Join Programming Pathshala for beginner-friendly lessons and career-ready skills."
                />
                <link rel="canonical" href="https://renaissance.programmingpathshala.com/low-level-design" />		
                <script type="application/ld+json">
                    {
                        `{
                          "@context": "https://schema.org",
                          "@type": "Course",
                          "name": "Low-Level Design - Renaissance by Programming Pathshala",
                          "description": "Learn Low-Level Design with no prior experience! Join Programming Pathshala for beginner-friendly lessons and career-ready skills.",
                          "url": "https://renaissance.programmingpathshala.com/low-level-design",
                          "educationalCredentialAwarded": "Certificate of Completion",
                          "provider": {
                            "@type": "Organization",
                            "name": "Programming Pathshala",
                            "url": "https://renaissance.programmingpathshala.com",
                            "sameAs": [
                              "https://www.facebook.com/programmingpathshala",
                              "https://www.linkedin.com/company/programming-pathshala/",
                              "https://www.instagram.com/programmingpathshala/"
                            ]
                          },
                          "hasCourseInstance": {
                            "@type": "CourseInstance",
                            "courseMode": "Online",
                            "instructor": {
                              "@type": "Person",
                              "name": "Vivekanand Vivek"
                            },
                            "location": {
                              "@type": "VirtualLocation",
                              "url": "https://renaissance.programmingpathshala.com"
                            }
                          },
                          "about": [
                            {
                              "@type": "Course",
                              "name": "Low-Level Design"
                            },
                            {
                              "@type": "Course",
                              "name": "Concurrency"
                            },
                            {
                              "@type": "Course",
                              "name": "System Design"
                            },
                            {
                              "@type": "Course",
                              "name": "Object-Oriented Programming System (OOPs)"
                            },
                            {
                              "@type": "Course",
                              "name": "SOLID Principles"
                            },
                            {
                              "@type": "Course",
                              "name": "Design Patterns"
                            }
                          ]
                        }`
                    }
                </script>
                <meta property="og:title" content="Low-Level Design for Beginners and Pros | Programming Pathshala Renaissance" />		
		        <meta property="og:description" content="Learn Low-Level Design from scratch with Programming Pathshala Renaissance. Perfect for beginners and professionals, master concepts with practical applications!" />		
		        <meta property="og:url" content="https://renaissance.programmingpathshala.com/low-level-design" />		
		        <meta property="og:type" content="website" />		
		        <meta property="og:image" content="/ppa-icon-img.png" />
            </Helmet>
            <div className="header-sticky">
                <Header />
            </div>
            <div>
                <Intro />
            </div>
            <div>
                <About />
            </div>
            <div>
                <Audience />
            </div>
            <div>
                <Advantages />
            </div>
            <div>
                <Curriculum />
            </div>
            <div>
                <Prerequisites />
            </div>
            <div>
                <Mentor course={availableCourses.LLD} />
            </div>
            <div>
                <SuccessStories />
            </div>
            <div>
                <Pricing couponCode={couponCode} setCouponCode={setCouponCode} />
            </div>
            <div>
                <Faq />
            </div>
            <div>
                <ExploreCourses course={availableCourses.LLD} />
            </div>
            <div>
                <ExploreBlogs course={availableCourses.LLD} />
            </div>
            <div>
                <FreeTrial />
            </div>
            <div>
                <Footer />
            </div>
        </div>
    );
};

export default LowLevelDesignLandingPage;
