import React from "react";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import "./styles/faq.css";

const FaqAccordion = (props) => {
  const handleAccordionClick = () => {
    props.recordAccordionId(props.identifier);
  };

  return (
    <div onClick={handleAccordionClick} className="ren-accordion-wrapper">
      <div
        className={
          (props.isOpen ? "ren-accordion-background" : " ") +
          " ren-accordion-item"
        }
      >
        {" "}
        <div className="ren-accordion-question">
          <h3>{props.accordionData.question}</h3>
          {props.isOpen ? <RemoveIcon /> : <AddIcon />}
        </div>
        <div
          className={
            (props.isOpen ? "ren-accodrion-show" : " ") +
            " ren-accodrion-content"
          }
        >
          <p>{props.accordionData.answer}</p>
        </div>
      </div>
    </div>
  );
};

export default FaqAccordion;
