import React from "react";
import RenaissanceAudienceContent from "./Content/RenaissanceAudienceContent";
import useWindowDimensions from "../../hooks/UseWindowDimensions";
import "./styles/Audience.css";

const Audience = () => {
  const { windowWidth } = useWindowDimensions();
  return (
    <div className="ren-audience-wrapper">
      <h2>This course is for you if you are</h2>
      {RenaissanceAudienceContent &&
        RenaissanceAudienceContent.map((data, index) => (
          <div className="ren-audience-card">
            <div className="ren-audience-content">
              <p className="customCss">{data.title}</p>
              <div className="ren-audience-description">{data.description}</div>
            </div>
            <div
              className={data.imgWrapper + " ren-audience-image-wrapper"}
              onClick={() => window.open(data.link)}
            >
              <img
                src={windowWidth > 600 ? data?.img : data?.mobileImg}
                alt="here"
                className="ren-audience-image"
              />
            </div>
          </div>
        ))}
    </div>
  );
};

export default Audience;
