import React from "react";
import { useNavigate } from "react-router-dom";
import { useGlobalContext } from "../../Contexts/GlobalContext";
import CompanyLogoDesktop from "../../img/Company_logo_LLD_Desktop.png";
import IntroLogo from "../../img/LowLevelIntroLogo.webp";
import IntroPoints from "./Content/IntroContent";
import "./styles/Intro.css";

import Rating from "@mui/material/Rating";
import { GTM_TRACKER } from "../../GoogleAnaytics/gtmConstants";
import { goToDashboard, startFreeTrial } from "../../courses/Strategies/RedirectionStrategy";

const IntroDesktop = () => {
    const navigate = useNavigate();
    const redirectUser = (link) => navigate(link);
    const { loggedIn, everSubscribed, freeTrialFormFilled } = useGlobalContext();

    function GetFreeTrialButton() {
        return (
            <div className="free-trial-btn-wrapper">
                <button onClick={() => startFreeTrial(freeTrialFormFilled, redirectUser)}>
                    Start Free Trial
                </button>
            </div>
        );
    }

    function GetDashBoardButton() {
        return (
            <div className="free-trial-btn-wrapper">
                <button onClick={() => goToDashboard()}>Go to Dashboard</button>
            </div>
        );
    }

    function GetSignupButton() {
        return (
            <div className={`${GTM_TRACKER.START_FREE_TRIAL} free-trial-btn-wrapper`}>
                <button
                    onClick={() => {
                        navigate("/signup");
                    }}
                >
                    Try 7-day Free Trial
                </button>
            </div>
        );
    }

    function GetFreeTrailOrDashboardButton() {
        if (!loggedIn) return <GetSignupButton />;
        else if (!everSubscribed) return <GetFreeTrialButton />;
        else return <GetDashBoardButton />;
    }

    return (
        <div className="intro-wrapper">
            <div className="intro-contents-wrapper">
                <h1 className="heading">
                    Master Low Level Design and Concurrency for{" "}
                    <span className="blue-heading">Software Professionals</span>{" "}
                </h1>
                <h2 className="desc">
                    Crack Design and Machine Coding Rounds for
                    <br /> Senior Software Engineer Roles
                </h2>
                {IntroPoints &&
                    IntroPoints.map((introPoint) => (
                        <div className="intro-points">
                            <img src={introPoint.img} alt="" />
                            <p>{introPoint.text}</p>
                        </div>
                    ))}

                <GetFreeTrailOrDashboardButton />

                <span className="free-lectures">
                    Watch 10+ lectures and a case study for <strong>free</strong>
                </span>
            </div>

            <div className="intro-image-wrapper">
                <div className="image-wrapper">
                    <img src={IntroLogo} alt="Learn low level design at programming pathshala" />
                </div>

                <div className="star-rating-wrapper">
                    <Rating
                        sx={{ color: "#444BAB" }}
                        name="half-rating-read"
                        defaultValue={5}
                        readOnly
                        size="medium"
                    />
                    <p className="star-rating-text">
                        Our courses are rated <strong>4.9</strong>/5 by{" "}
                        <strong>5k+ students</strong>
                    </p>
                </div>

                <div className="company-logo-wrapper">
                    <p>Required skill-sets by Top Tech and HFT Firms like:</p>
                    <div className="company-logo-img">
                        <img src={CompanyLogoDesktop}></img>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default IntroDesktop;
