import React from "react";
import { useNavigate } from "react-router-dom";
import {
    ADMIN_WHATSAPP_MESSAGE,
    PPA_ADMIN_PHONE_NUMBER,
    WHATS_APP_LINK,
} from "../../Constants/LandingPageConstants";
import { useGlobalContext } from "../../Contexts/GlobalContext";
import { GTM_TRACKER } from "../../GoogleAnaytics/gtmConstants";
import { goToDashboard, startFreeTrial } from "../../courses/Strategies/RedirectionStrategy";
import useWindowDimensions from "../../hooks/UseWindowDimensions";
import emptyInboxImg from "../../img/empty-inbox.svg";
import whatsappIcon from "../../img/whatsapp-icon.svg";
import FreeTrialMobile from "./FreeTrialMobile";
import "./styles/FreeTrial.css";

const FreeTrial = () => {
    const { freeTrialFormFilled, loggedIn, everSubscribed } = useGlobalContext();
    const navigate = useNavigate();
    const { windowWidth } = useWindowDimensions();
    const redirectUser = (link) => navigate(link);
    const openWhatsapp = () => {
        window.open(WHATS_APP_LINK + PPA_ADMIN_PHONE_NUMBER + ADMIN_WHATSAPP_MESSAGE, "_blank");
    };
    function GetFreeTrialButton() {
        return (
            <div
                className="free-trial-btn"
                onClick={() => startFreeTrial(freeTrialFormFilled, redirectUser)}
            >
                <button>Start 7 Day Free Trial</button>
            </div>
        );
    }

    function GetDashBoardButton() {
        return (
            <div className="free-trial-btn" onClick={() => goToDashboard()}>
                <button>Go To Dashboard</button>
            </div>
        );
    }

    function GetSignupButton() {
        return (
            <div
                className={`${GTM_TRACKER.START_FREE_TRIAL} free-trial-btn`}
                onClick={() => {
                    navigate("/signup");
                }}
            >
                <button>Try Our 7-Day Free Trial</button>
            </div>
        );
    }
    function GetFreeTrailOrDashboardButton() {
        if (!loggedIn) return <GetSignupButton />;
        else if (!everSubscribed) return <GetFreeTrialButton />;
        else return <GetDashBoardButton />;
    }

    function FreeTrialDesktop() {
        return (
            <div className="lld-free-trial-wrapper">
                <p>
                    Still not convinced? Take a free trial of our course
                    <br /> and see the difference yourself
                </p>
                <div className="buttons-wrapper">
                    <GetFreeTrailOrDashboardButton />

                    <div
                        className={`${GTM_TRACKER.WHATSAPP} talk-to-us-btn`}
                        onClick={openWhatsapp}
                    >
                        <p>Talk to us</p>
                        <img src={whatsappIcon} alt="" />
                    </div>
                </div>

                <div className="lld-empty-inbox-img">
                    <img src={emptyInboxImg} alt="" />
                </div>
            </div>
        );
    }

    return (
        <div className="free-trial">
            {windowWidth <= 600 ? <FreeTrialMobile /> : <FreeTrialDesktop />}
        </div>
    );
};

export default FreeTrial;
