import React, { useState } from "react";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import trophyIcon from "../../img/trophy-icon.png";
import "./styles/OurCurriculumWrapper.css";

const CurriculumModuleAccordion = (props) => {
  const [clicked, setClicked] = useState(false);
  const handleClick = () => {
    setClicked((prev) => !prev);
  };
  return (
    <div>
      <section className="course-module-section" key={props.index}>
        <div className="course-module-title-wrapper" onClick={handleClick}>
          <div
            className={clicked ? "clicked-module-title" : "course-module-title"}
          >
            {clicked ? (
              <KeyboardArrowDownIcon style={{ color: "#64748B" }} />
            ) : (
              <ChevronRightIcon style={{ color: "#64748B" }} />
            )}
            <img
              src={props.data.moduleImage}
              alt=""
              className="course-module-image"
            />
            <h3 className={clicked ? "customCss-clicked-module-title" : "customCss-course-module-title"}>
              {props.data.moduleTitle}
            </h3>
          </div>
          <div className="course-module-duration">{props.data.duration}</div>
        </div>

        <div className={clicked ? "course-line-break" : ""}></div>
       
          
            <div className= {(clicked ? "course-module-topics" : " ") + " course-module-content"}>
              {props.data.moduleTopics}
              <div className="ren-subtopics-wrapper">
                {props.data && props.data.subTopics.map((item, index) => (
                  <div className="subtopics-content">
                    {item.heading ? (
                      <h4 className="subtopics-heading">{item.heading}</h4>
                    ) : (
                      <div></div>
                    )}
                    <div className="subtopics-points-wrapper">
                      {item.content.map((item) => (
                        <div className="subtopics-points">
                          <img
                            src={trophyIcon}
                            alt=""
                            className="trophy-icon"
                          />
                          {item}
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            </div>
      </section>
    </div>
  );
};

export default CurriculumModuleAccordion;
