import React from "react";
import "./styles/Mentor.css";
import { MentorCardDetails, MentorHeadings } from "./MentorCardDetails";
import linkedin_icon from "../../img/linkedin.png";
import gmail_icon from "../../img/gmail-logo.png";
import amazonLogo from "../../img/amazon-logo.png";
import useWindowDimensions from "../../hooks/UseWindowDimensions";

const Mentor = (props) => {
    const mentor = MentorCardDetails;
    const { windowWidth } = useWindowDimensions();
    const currentCourse = props.course;
    return (
        <div className="mentor-wrapper">
            {MentorHeadings[currentCourse]}
            <div className="mentor-content-wrapper">
                <div className="mentor-img-wrapper">
                    <div className="mentor-img">
                        {windowWidth > 600 ? <img src={mentor.img} alt="Learn low level design in 6 months" /> :
                            <img src={mentor.imgMob} alt="Learn low level design in 6 months" />
                        }
                    </div>
                    <div className="mentor-details-wrapper">
                        <div className="mentor-name-linkedin-gmail">
                            <p className="mentor-name">{mentor.name}</p>
                            <a href={mentor.link}>
                                <img className="mentor-linkedin-icon" src={linkedin_icon} alt="" />
                            </a>
                            <a href={mentor.gmail}>
                                <img className="mentor-gmail-icon" src={gmail_icon} alt="" />
                            </a>
                        </div>
                        <div className="mentor-company-wrapper">
                            <p className="mentor-company-text">IIT BHU, Ex-</p>
                            <img src={amazonLogo} alt="" />
                        </div>
                    </div>
                </div>
                <div className="mentor-description">
                    <p>{mentor.description}</p>
                </div>
            </div>
        </div>
    );
};

export default Mentor;
