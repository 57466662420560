import React, { useState } from "react";
import "./styles/RepublicDayBanner.css";
import flag from '../../img/republicFlag.svg'; 
import frame from "../../img/Frame 20548.png";
import { handleApplyCouponCode } from "../../Utilities/BannerUtils";

const RepublicDayBanner = ({ setCouponCode }) => {
    return (
        <div className="banner-container">
            <img src={frame} alt="Frame" className="frame"/>
            <div className="flag-container">
                <img src={flag} alt='Indian Flag' className=""/>
            </div>
            <p className="banner-text">
                <p className="text">
                    <span className="message">Don't Delay, Enroll Today - Get </span>
                    <span className="offer">40% OFF </span><span className="message">with</span> <span className="offer"> 75YEARS</span>
                </p>
                <button className="banner-button" onClick={() => handleApplyCouponCode({ couponCode: "75YEARS", setCouponCode })}>
                    Claim Now
                </button>
            </p>

        </div>
    );
};

export default RepublicDayBanner;
