import React from "react";
import ActionButton from "../Common/ActionButton";
import "../../components/LandingPage/styles/OurCurriculumWrapper.css";
import ourCurriculumImg from "../../img/ourCurriculumMentorImg.png";
import downloadIcon from "../../img/download-btn-ren.png";
import { style } from "../Common/Theme";
import useWindowDimensions from "../../hooks/UseWindowDimensions";
import {
  curriculumModuleDetails,
  curriculumsubjectsData,
  curriculumElectivesDetails,
} from "../../Constants/OurCirriculumConstant";
import curriculumPdf from "../../CurriculumPdf/RenaissancePlusDetailedCurriculum.pdf";
import ticks from "./../../img/ticks.svg";
import { sendEventToGTM } from "../../GoogleAnaytics/gtmManager";
import { getCurrentCourse } from "../../courses/CourseUtility";
import { GTM_TRACKER } from "../../GoogleAnaytics/gtmConstants";
import CurriculumModuleAccordion from "./CurriculumModuleAccordion";

const getOurCurriculumContents = () => {
  return (
    <>
      <div className="our-curriculum-content">
        <h3>Our Curriculum</h3>
        <h2>Learn with a Proven Curriculum to become</h2>
        <p className="our-curriculum-subheading">
          {" "}
          100% Coding Interview Ready
        </p>
        <p className="our-curriculum-desc">
          Our comprehensive curriculum covers:
        </p>

        {curriculumsubjectsData &&
          curriculumsubjectsData.map((data, index) => (
            <div key={index} className="our-curriculum-subjects">
              <img src={ticks} alt="" />
              <p>{data}</p>
            </div>
          ))}

        <p className="our-curriculum-desc">
          With this course, we promise that you won’t need to look anywhere
          else!
        </p>
      </div>
    </>
  );
};

const OurCurriculum = () => {
  const { windowWidth } = useWindowDimensions();

  return (
    <div className="our-curriculum-wrapper">
      <div className="our-curriculum-content">
      <h2 className="customCss">Course Curriculum</h2>
      <div className="course-modules-wrapper">
      {curriculumModuleDetails &&
        curriculumModuleDetails.map((data, index) => (
            <CurriculumModuleAccordion  data={data} index={index} />
        ))}
          <p className="electives-heading">Electives</p> 
           {curriculumElectivesDetails &&
        curriculumElectivesDetails.map((data, index) => (
            <CurriculumModuleAccordion  data={data} index={index} />
        ))}
         <p className="course-guidance">If you need guidance for choosing your Electives, our Mentors and Counsellors will help you out.</p>
    </div>
      
      </div>
      {/* change later to track leads */}
        <a
            className={GTM_TRACKER.DOWNLOAD_CURRICULUM}
            href={curriculumPdf}
            rel="noopener noreferrer"
            target="_blank"
        >
            <div className="ren-download-curriculum">
                <img src={downloadIcon} alt="" />
                <p>Download Detailed Curriculum</p>
            </div>
        </a>
    </div>
  );
};

export default OurCurriculum;
