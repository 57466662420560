import dsaImage from "../../img/DSA.png";
import renImage from "../../img/ren-banner.png";
import lldConcurrencyImage from "../../img/lld-concurrency-banner.png";
import pythonImage from "../../img/python-banner.svg";
import cppImage from "../../img/cpp-banner.svg";
import reactImage from "../../img/react-banner.svg";
import dsaEssentialsImage from "../../img/dsa-essentials-banner.png";
import springbootImage from "../../img/SpringBootImg.svg"
import { availableCourses } from "../../courses/CourseConstants";
import { gaCourseNameMap } from "../../courses/CourseConstants";
import { GA_CUSTOM_COURSE_PARAMS } from "../../GoogleAnaytics/gtmConstants";

const CourseContents = {
    courseLLDHeading: (
        <h2 className="lld-course-heading">
            Explore <span className="blue-heading-tag">Our Other Courses</span>
        </h2>
    ),
    coursePythonHeading: (
        <h1 className="python-course-heading">
            Explore Our <strong>Other Courses</strong>
        </h1>
    ),
    courseCPPHeading: (
        <h1 className="cpp-course-heading">
            Explore <strong>Other Courses</strong>
        </h1>
    ),
    courseRenHeading: <h2 className="ren-course-heading">Explore Our Other Courses</h2>,
    courseDsaHeading: <h1 className="dsa-course-heading">Explore Other Courses</h1>,
    courseCardDetails: [
        {
            courseBannerImg: renImage,
            courseDescription:
                "Master Data Structures & Algorithms and System Design. Crack Top-notch Tech roles.",
            ctaButton: "Know More",
            courseLink: `${process.env.REACT_APP_FRONTEND_MAIN_URL}crack-coding-interviews`,
            clickable: true,
            courseName: availableCourses.RENAISSANCE,
            gaCourseTracker: gaCourseNameMap.renaissance,
        },
        {
            courseBannerImg: springbootImage,
            courseDescription:
                "Master developing stable & scalable backends using Spring Boot, Hibernate,JPA, and more.",
            ctaButton: "Know More",
            courseLink: `${process.env.REACT_APP_FRONTEND_MAIN_URL}java-springboot`,
            clickable: true,
            courseName: availableCourses.JAVA_SPRINGBOOT,
            gaCourseTracker: gaCourseNameMap["java-springboot"],
        },
        {
            courseBannerImg: lldConcurrencyImage,
            courseDescription:
                "Design and write highly extensible, maintainable and readable code. Mandatory skill for Senior Engineers.",
            ctaButton: "Know More",
            courseLink: `${process.env.REACT_APP_FRONTEND_MAIN_URL}low-level-design`,
            clickable: true,
            courseName: availableCourses.LLD,
            gaCourseTracker: gaCourseNameMap["low-level-design"],
        },
        {
            courseBannerImg: dsaEssentialsImage,
            courseDescription:
                "A very concise program with curated and handpicked concepts, for you to master DSA quickly and crack your dream job.",
            ctaButton: "Know More",
            courseLink: `${process.env.REACT_APP_FRONTEND_MAIN_URL}dsa-and-algorithms-essentials`,
            clickable: true,
            courseName: availableCourses.CRASH_COURSE,
            gaCourseTracker: gaCourseNameMap["crash-course"],
        },
        {
            courseBannerImg: cppImage,
            courseDescription:
                "A practical and hands-on course for those who are eager to master C++. Beginner-friendly.",
            ctaButton: "Know More",
            courseLink: `${process.env.REACT_APP_FRONTEND_MAIN_URL}begin-with-c++`,
            clickable: true,
            courseName: availableCourses.CPP,
            gaCourseTracker: gaCourseNameMap.cpp,
        },

        {
            courseBannerImg: pythonImage,
            courseDescription:
                "Become a Python pro and unlock the door to endless career opportunities",
            courseLink: `${process.env.REACT_APP_FRONTEND_MAIN_URL}begin-with-python`,
            clickable: true,
            ctaButton: "Know More",
            courseName: availableCourses.PYTHON,
            gaCourseTracker: gaCourseNameMap.python,
        },
        {
            courseBannerImg: reactImage,
            courseDescription:
                "Master the skills of a front-end engineer and build modern & responsive website applications.",
            courseLink: `${process.env.REACT_APP_FRONTEND_MAIN_URL}become-a-frontend-engineer`,
            clickable: true,
            ctaButton: "Know More",
            courseName: availableCourses.REACT,
            gaCourseTracker: gaCourseNameMap.react,
        },
    ],
};
export { CourseContents };
