import React from "react";
import { useState } from "react";
import "./styles/Curriculum.css";
import { LowLevelDesignCurriculum, ConcurrrencyCurriculum } from "./Content/CurriculumContent";
import LowLevelDesignConcurrencyPdf from "../../CurriculumPdf/LowLevelDesignConcurrencyCurriculum.pdf";
import downloadIcon from "../../img/download-btn-lld.png";
import useWindowDimensions from "../../hooks/UseWindowDimensions";
import { sendEventToGTM } from "../../GoogleAnaytics/gtmManager";
import { getCurrentCourse } from "../../courses/CourseUtility";
import { GTM_TRACKER } from "../../GoogleAnaytics/gtmConstants";
const Curriculum = () => {
    const [showMoreLLD, setShowMoreLLD] = useState(false);
    const [showMoreConcurrency, setShowMoreConcurrency] = useState(false);
    const { windowWidth } = useWindowDimensions();

    const toggleReadMoreLessLLD = () => {
        setShowMoreLLD(!showMoreLLD);
    };

    const toggleReadMoreLessConcurrency = () => {
        setShowMoreConcurrency(!showMoreConcurrency);
    };
    const getMobileContent = (curriculumContent, showMore, toggleReadMoreLess) => {
        return (
            <>
                {curriculumContent &&
                    curriculumContent.map((curriculum, index) => (
                        <>
                            {index < 3 ? (
                                <div className="curriculum-card">
                                    <h4>{curriculum.heading}</h4>
                                    {curriculum.data &&
                                        curriculum.data.map((data) => <p>{data}</p>)}
                                </div>
                            ) : showMore ? (
                                <div className="curriculum-card">
                                    <h4>{curriculum.heading}</h4>
                                    {curriculum.data &&
                                        curriculum.data.map((data) => <p>{data}</p>)}
                                </div>
                            ) : (
                                <></>
                            )}
                        </>
                    ))}
                <div className="show-more-wrapper" onClick={toggleReadMoreLess}>
                    <p className="show-more-text">{!showMore ? "Show More" : "Show Less"}</p>
                </div>
            </>
        );
    };

    const getDesktopContent = (curriculumContent) => {
        return (
            <>
                {curriculumContent &&
                    curriculumContent.map((curriculum) => (
                        <div className="curriculum-card">
                            <h4>{curriculum.heading}</h4>
                            {curriculum.data && curriculum.data.map((data) => <p>{data}</p>)}
                        </div>
                    ))}
            </>
        );
    };
    const getCurriculum = (title, curriculumContent, showMore, toggleReadMoreLess) => {
        return (
            <div className="curriculum-card-wrapper">
                <div className="curriculum-header-wrapper">
                    <h3>{title}</h3>
                </div>

                {windowWidth <= 600
                    ? getMobileContent(curriculumContent, showMore, toggleReadMoreLess)
                    : getDesktopContent(curriculumContent)}
            </div>
        );
    };

    return (
        <div className="curriculum-container">
            <h2>
                Course <span className="blue-heading-tag">Curriculum</span>
            </h2>

            <div
                className={
                    windowWidth <= 600
                        ? "mob-curriculum-section-wrapper"
                        : "curriculum-section-wrapper"
                }
            >
                {getCurriculum(
                    "Low Level Design",
                    LowLevelDesignCurriculum,
                    showMoreLLD,
                    toggleReadMoreLessLLD
                )}
                {getCurriculum(
                    "Concurrency",
                    ConcurrrencyCurriculum,
                    showMoreConcurrency,
                    toggleReadMoreLessConcurrency
                )}
            </div>

            <a
                className={GTM_TRACKER.DOWNLOAD_CURRICULUM}
                href={LowLevelDesignConcurrencyPdf}
                rel="noopener noreferrer"
                target="_blank"
            >
                <div className="download-curriculum">
                    <img src={downloadIcon} alt="" />
                    <p>Download Curriculum</p>
                </div>
            </a>
        </div>
    );
};

export default Curriculum;
