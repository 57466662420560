import React from "react";
import Slider from "react-slick";
import arrowPrev from "../../img/left-arrow-blue.png";
import arrowNext from "../../img/right-arrow-blue.png";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./styles/Advantage.css";
import useWindowDimensions from "../../hooks/UseWindowDimensions";
import renAdvantage from "./../../img/ren-advantage.webp";
import beginProgramming from "./../../img/advantage-beginprog.png";
import dsa from "./../../img/advantage-dsa.png";
import liveClass from "./../../img/advantage-liveclass.png";
import systemDesign from "./../../img/advantage-design.png";
import placement from "./../../img/advantage-placement.png";
function NextArrow(props) {
  const { className, onClick } = props;
  return (
    <img className={className} onClick={onClick} src={arrowNext} alt="next" />
  );
}

function PrevArrow(props) {
  const { className, onClick } = props;
  return (
    <img className={className} onClick={onClick} src={arrowPrev} alt="prev" />
  );
}

const Advantage = () => {
  const { windowWidth } = useWindowDimensions();

  const getSlidingContent = () => {
    const sliderSettings = {
      dots: true,
      infinite: false,
      autoplay: false,
      speed: 500,
      slidesToShow: 3,
      arrows: true,
      slidesToScroll: 1,
      customPaging: function (i) {
        return <a />;
      },
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />,
      responsive: [
        {
          breakpoint: 601,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            dots: true,
          },
        },
      ],
    };
    return (
      <div className="advantage-slider">
        <Slider {...sliderSettings}>
          <div>
            <div className="advantage-slider-img-wrapper">
              <img src={beginProgramming} alt="" className="advantage-slider-img" />
            </div>
          </div>
          <div>
            <div className="advantage-slider-img-wrapper">
              <img src={dsa} alt="" className="advantage-slider-img" />
            </div>
          </div>
          <div>
            <div className="advantage-slider-img-wrapper">
              <img src={liveClass} alt="" className="advantage-slider-img" />
            </div>
          </div>
          <div>
            <div className="advantage-slider-img-wrapper">
              <img src={systemDesign} alt="" className="advantage-slider-img" />
            </div>
          </div>

          <div>
            <div className="advantage-slider-img-wrapper">
              <img src={placement} alt="" className="advantage-slider-img" />
            </div>
          </div>
        </Slider>
      </div>
    );
  };

  return (
    <div className="advantage-wrapper">
      <h2>Get the Programming Pathshala Advantage</h2>
      <h3>Your Learning Outcomes will be:</h3>

      {windowWidth < 600 ? (
        getSlidingContent()
      ) : (
        <img src={renAdvantage} alt="" className="advantage-desktop-img" />
      )}
    </div>
  );
};

export default Advantage;
